<template>
  <v-app>
    <v-overlay :value="isSSOLoading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-row
      justify="center"
      style="height: 100%;"
    >
      <v-col
        cols="10"
        sm="8"
        md="4"
        lg="4"
        class="my-auto"
      >
        <!--  -->

        <!--  -->
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Login
            </div>
          </template>

          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="login"
          >
            <v-container fluid>
              <v-text-field
                v-model="email"
                name="email"
                :rules="[rules.required]"
                label="E-mail"
              />

              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min_8]"
                :type="show ? 'text' : 'password'"
                name="password"
                label="Password"
                @click:append="show = !show"
              />
              <!-- <router-link :to="{ name: 'forgot.password' }">
                {{ $t("forgot_password") }}
              </router-link> -->
              <v-row>
                <!-- <v-col class="d-flex align-end">
                  <div
                    class="LoginBtn"
                    @click="loginWithAzure"
                  >
                    Login with Azure
                  </div>
                </v-col> -->
                <v-col class="text-right">
                  <v-btn
                    :loading="isLoading"
                    class="mr-4 mt-4"
                    type="submit"
                    color="primary"
                    :disabled="!valid"
                    @click="validate"
                  >
                    <!-- <v-btn class="mr-4 mt-4" type="submit" @click="validate" color="#9C27b0" :disabled="!valid"> -->
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
// import Localbase from "localbase";

/* eslint-disable */
// let db = new Localbase("db");
// db.config.debug = false;
export default {
  components: {
    BaseMaterialCard: () => import("@/components/base/BaseMaterialCard.vue"),
  },
  mixins: [validationRules],
  name: "Login",
  data() {
    return {
      isSSOLoading: false,
      isLoading: false,
      show: false,
      password: "",
      email: "",
      valid: false,
    };
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },

  created() {
    // Verify Auth for SSO
    // if (this.$route.query.success) {
    //   this.isSSOLoading = true;
    //   this.$store
    //     .dispatch("verifySSO", this.$route.query.token)
    //     .catch((err) => {
    //       this.isSSOLoading = false;
    //       this.$store.dispatch("alerts/error", err);
    //     })
    //     .finally(() => (this.isSSOLoading = false));
    // } else if (this.$route.query.error) {
    //   this.$store.dispatch("alerts/error", this.$route.query.msg);
    // }
    // Verify Auth for SSO
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },

    login() {
      var attributes = {
        email: this.email,
        password: this.password,
      };
      this.isLoading = true;
      this.$store
        .dispatch("login", attributes)
        .then(() => {
          this.$store.dispatch("alerts/success", this.$t("request_successful"));
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("alerts/error", error);
        });
    },
    // async loginWithAzure() {
    //   this.isSSOLoading = true;
    //   await this.$store.dispatch("loginWithAzure");
    //   this.isSSOLoading = false;
    // },
  },
};
</script>
